import { BaseService } from './base-service';
import { ResponsibleDTO, GetResponsibleOptionsParamsDTO } from '@/core/dto/responsible';

export class UserService extends BaseService {
	private static instance: UserService;

	constructor(public readonly basePath: string = '/user') {
		super();
	}

	public static getInstance(): UserService {
		if (!UserService.instance) {
			UserService.instance = new UserService();
		}
		return UserService.instance;
	}

	async listOptions(params: GetResponsibleOptionsParamsDTO): Promise<ResponsibleDTO[]> {
		const url = this.basePath + `/`;
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}
}
