import Api from '@/services/api';
import { BaseService } from './base-service';
import {
	ActionPlanOriginRequestDTO,
	ActionPlanOriginResponseDTO,
	CreateActionPlanRequestDTO,
	CreateActionPlanResponseDTO,
	ActionPlanCardBulkDeleteDTO,
	ActionPlanCardBulkDeleteResponseDTO,
	ActionPlanCardBulkUpdateDTO,
	ActionPlanCardBulkUpdateResponseDTO,
	ActionPlanListRequestDTO,
	ActionPlanListDTO,
	ActionPlanCreateUserPreferenceColumnsRequestDTO,
	ActionPlanCreateUserPreferenceColumnsDTO,
	ActionPlanGetUserPreferenceColumnsRequestDTO,
	OriginOptionResponseDTO,
	ActionPlanUpdateNotificationRequestDTO,
	ActionPlanUpdateNotificationResponseDTO,
	ActionPlanCountRequestDTO,
	ActionPlanCountResponseDTO,
	ActionPlanResponseDTO,
	UpdateActionPlanRequestDTO,
	UpdateActionPlanResponseDTO,
	ActionPlanRequestDTO,
	ActionPlanCreateTasksRequestDTO,
	ActionPlanCreateTasksResponseDTO,
	ActionPlanDeleteTaskRequestDTO,
	ActionPlanDeleteTaskResponseDTO,
	ActionPlanUpdateTaskRequestDTO,
	ActionPlanUpdateTaskResponseDTO,
	ActionPlanUpdateTaskStatusRequestDTO,
	ActionPlanUpdateTaskStatusResponseDTO,
	AddEvidenceResponseDTO,
	AddEvidenceRequestDTO,
	GetEvidencesRequestDTO,
	ActionPlanAttachmentDownloadUrlRequestDTO,
	ActionPlanTaskAttachmentDownloadUrlRequestDTO,
	DeleteEvidenceResponseDTO,
	DeleteEvidenceRequestDTO,
	UpdateEvidenceRequestDTO,
	UpdateEvidenceResponseDTO,
	ActionPlanTasksRequestDTO,
	ActionPlanTasksResponseDTO,
	GetAttachmentsRequestDTO,
	GetRelatedReportsRequestDTO,
	GetRelatedReportsResponseDTO,
	UpdateAttachmentRequestDTO,
	UpdateAttachmentResponseDTO,
	DeleteAttachmentRequestDTO,
	DeleteAttachmentResponseDTO,
	AddAttachmentRequestDTO,
	AddAttachmentResponseDTO,
	SetRelatedReportsRequestDTO,
	SetRelatedReportsResponseDTO,
	ActionPlanHistoryRequestDTO,
	ActionPlanHistoryDTO,
	ActionPlanCommentsRequestDTO,
	ActionPlanCommentsDTO,
	ActionPlanUpdateCommentsRequestDTO,
	ActionPlanUpdateCommentsDTO,
	ActionPlanDeleteCommentsRequestDTO,
	ActionPlanDeleteCommentsDTO,
	ActionPlanCreateCommentsRequestDTO,
	ActionPlanCreateCommentsDTO,
	UpdateCardRankRequestDTO,
	UpdateCardRankResponseDTO,
	ActionPlanCardListResponseDTO,
	ActionPlanCardListRequestDTO,
	ActionPlanPdfEditRequestDTO,
	CreateActionPlanFromReportRequestDTO,
	CreateActionPlanFromReportResponseDTO
} from '@/core/dto';

import {
	ActionPlanListDomain,
	ActionPlanCreateUserPreferenceColumnsDomain,
	UploadFileItem,
	ActionPlanHistoryDomain,
	ActionPlanCommentsDomain,
	ActionPlanUpdateCommentsDomain,
	ActionPlanDeleteCommentsDomain,
	ActionPlanCreateCommentsDomain
} from '@/core/domain';

import {
	ActionPlanListMapper,
	ActionPlanCreateUserPreferenceColumnsMapper,
	ActionPlanAttachmentsMapper,
	ActionPlanHistoryMapper,
	ActionPlanCommentsMapper,
	ActionPlanUpdateCommentsMapper,
	ActionPlanDeleteCommentsMapper,
	ActionPlanCreateCommentsMapper
} from '@/core/mappers/action-plans';
import {
	GetFileActionPlansRequestDTO,
	GetFileActionPlansResponseDTO
} from '@/core/dto/action-plans/action-plan-get-file';

export class ActionPlanService extends BaseService {
	private static instance: ActionPlanService;

	constructor(public readonly basePath: string = '/action_plan/v2') {
		super();
	}

	public static getInstance(): ActionPlanService {
		if (!ActionPlanService.instance) {
			ActionPlanService.instance = new ActionPlanService();
		}
		return ActionPlanService.instance;
	}

	public async create(params: CreateActionPlanRequestDTO): Promise<CreateActionPlanResponseDTO> {
		const url = this.basePath + '/';
		const { data } = await Api.post(url, params);
		return data;
	}

	async list(params: ActionPlanListRequestDTO): Promise<ActionPlanListDomain> {
		const url = this.basePath + '/';
		const { data } = await Api.get<ActionPlanListDTO>(url, { params });
		return new ActionPlanListMapper(data).toDomain();
	}

	public async origins(params: ActionPlanOriginRequestDTO): Promise<ActionPlanOriginResponseDTO> {
		const url = this.basePath + '/origin';
		const { data } = await Api.get<ActionPlanOriginResponseDTO>(url, { params });
		return data;
	}

	async bulkDelete(params: ActionPlanCardBulkDeleteDTO): Promise<ActionPlanCardBulkDeleteResponseDTO> {
		const url = this.basePath + '/';
		const { data } = await Api.delete(url, { params });
		return data;
	}

	async bulkUpdate(params: ActionPlanCardBulkUpdateDTO): Promise<ActionPlanCardBulkUpdateResponseDTO> {
		const url = this.basePath + '/bulk-update';
		const { data } = await Api.put(url, params);
		return data;
	}

	async createPreferenceColumns(
		params: ActionPlanCreateUserPreferenceColumnsRequestDTO
	): Promise<ActionPlanCreateUserPreferenceColumnsDomain> {
		const url = this.basePath + '/user-preference/columns';
		const { data } = await Api.post<ActionPlanCreateUserPreferenceColumnsDTO>(url, params);
		return new ActionPlanCreateUserPreferenceColumnsMapper(data).toDomain();
	}

	async getUserPreferenceColumns(params: ActionPlanGetUserPreferenceColumnsRequestDTO): Promise<string[]> {
		const url = this.basePath + '/user-preference/columns';
		const { data } = await Api.get<string[]>(url, { params });
		return data;
	}

	async getOriginOptions(): Promise<OriginOptionResponseDTO[]> {
		const url = this.basePath + '/origin/option';
		const { data } = await this.getInstance().get<OriginOptionResponseDTO[]>(url);
		return data;
	}

	async getNotifications(): Promise<string[]> {
		const url = this.basePath + '/user-preference/notification';
		const { data } = await this.getInstance().get<string[]>(url);
		return data;
	}

	async updateNotification(
		params: ActionPlanUpdateNotificationRequestDTO
	): Promise<ActionPlanUpdateNotificationResponseDTO> {
		const url = this.basePath + '/user-preference/notification';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async countAll(params: ActionPlanCountRequestDTO): Promise<ActionPlanCountResponseDTO> {
		const url = this.basePath + '/count-all';
		const { data } = await this.getInstance().get<ActionPlanCountResponseDTO>(url, { params });
		return data;
	}

	async findOne(params: ActionPlanRequestDTO): Promise<ActionPlanResponseDTO> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().get<ActionPlanResponseDTO>(url, { params });
		return data;
	}

	async update(params: UpdateActionPlanRequestDTO): Promise<UpdateActionPlanResponseDTO> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().put<UpdateActionPlanResponseDTO>(url, params);
		return data;
	}

	async getTasks(params: ActionPlanTasksRequestDTO): Promise<any[]> {
		const url = this.basePath + '/task';
		const { data } = await this.getInstance().get<ActionPlanTasksResponseDTO[]>(url, { params });
		return data;
	}

	async createTask(params: ActionPlanCreateTasksRequestDTO): Promise<ActionPlanCreateTasksResponseDTO> {
		const url = this.basePath + '/task';
		const { data } = await this.getInstance().post<ActionPlanCreateTasksResponseDTO>(url, params);
		return data;
	}

	async deleteTask(params: ActionPlanDeleteTaskRequestDTO): Promise<ActionPlanDeleteTaskResponseDTO> {
		const url = this.basePath + '/task';
		const { data } = await this.getInstance().delete<ActionPlanDeleteTaskResponseDTO>(url, { params });
		return data;
	}

	async updateTask(params: ActionPlanUpdateTaskRequestDTO): Promise<ActionPlanUpdateTaskResponseDTO> {
		const url = this.basePath + '/task';
		const { data } = await this.getInstance().put<ActionPlanUpdateTaskResponseDTO>(url, params);
		return data;
	}

	async updateTaskStatus(
		params: ActionPlanUpdateTaskStatusRequestDTO
	): Promise<ActionPlanUpdateTaskStatusResponseDTO> {
		const url = this.basePath + '/task/completed';
		const { data } = await this.getInstance().put<ActionPlanUpdateTaskStatusResponseDTO>(url, params);
		return data;
	}

	async addEvidence(params: AddEvidenceRequestDTO): Promise<AddEvidenceResponseDTO> {
		const url = this.basePath + '/task/attachment';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async getEvidences(params: GetEvidencesRequestDTO): Promise<UploadFileItem[]> {
		const url = this.basePath + '/task/attachment';
		const { data } = await this.getInstance().get(url, { params });
		return new ActionPlanAttachmentsMapper(data).toDomain();
	}

	async getAttachmentDownloadUrl(params: ActionPlanAttachmentDownloadUrlRequestDTO): Promise<string> {
		const url = this.basePath + '/attachment/download';
		const { data } = await this.getInstance().get<string>(url, { params });
		return data;
	}

	async getTaskAttachmentDownloadUrl(params: ActionPlanTaskAttachmentDownloadUrlRequestDTO): Promise<string> {
		const url = this.basePath + '/task/attachment/download';
		const { data } = await this.getInstance().get<string>(url, { params });
		return data;
	}

	async deleteEvidence(params: DeleteEvidenceRequestDTO): Promise<DeleteEvidenceResponseDTO> {
		const url = this.basePath + '/task/attachment';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async updateEvidence(params: UpdateEvidenceRequestDTO): Promise<UpdateEvidenceResponseDTO> {
		const url = this.basePath + '/task/attachment';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async getAttachments(params: GetAttachmentsRequestDTO): Promise<UploadFileItem[]> {
		const url = this.basePath + '/attachment';
		const { data } = await this.getInstance().get(url, { params });
		return new ActionPlanAttachmentsMapper(data).toDomain();
	}

	async getRelatedReports(params: GetRelatedReportsRequestDTO): Promise<GetRelatedReportsResponseDTO[]> {
		const url = this.basePath + '/related-report';
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}

	async updateAttachment(params: UpdateAttachmentRequestDTO): Promise<UpdateAttachmentResponseDTO> {
		const url = this.basePath + '/attachment';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async deleteAttachment(params: DeleteAttachmentRequestDTO): Promise<DeleteAttachmentResponseDTO> {
		const url = this.basePath + '/attachment';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async attachment(params: AddAttachmentRequestDTO): Promise<AddAttachmentResponseDTO> {
		const url = this.basePath + '/attachment';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async setRelatedReports(params: SetRelatedReportsRequestDTO): Promise<SetRelatedReportsResponseDTO> {
		const url = this.basePath + '/related-report';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async getHistory(params: ActionPlanHistoryRequestDTO): Promise<ActionPlanHistoryDomain> {
		const url = this.basePath + '/history';
		const { data } = await this.getInstance().get<ActionPlanHistoryDTO>(url, { params });
		return new ActionPlanHistoryMapper(data).toDomain();
	}

	async getComments(params: ActionPlanCommentsRequestDTO): Promise<ActionPlanCommentsDomain> {
		const url = this.basePath + '/comment';
		const { data } = await this.getInstance().get<ActionPlanCommentsDTO>(url, { params });
		return new ActionPlanCommentsMapper(data).toDomain();
	}

	async updateComments(params: ActionPlanUpdateCommentsRequestDTO): Promise<ActionPlanUpdateCommentsDomain> {
		const url = this.basePath + '/comment';
		const { data } = await this.getInstance().put<ActionPlanUpdateCommentsDTO>(url, params);
		return new ActionPlanUpdateCommentsMapper(data).toDomain();
	}

	async deleteComments(params: ActionPlanDeleteCommentsRequestDTO): Promise<ActionPlanDeleteCommentsDomain> {
		const url = this.basePath + '/comment';
		const { data } = await this.getInstance().delete<ActionPlanDeleteCommentsDTO>(url, { params });
		return new ActionPlanDeleteCommentsMapper(data).toDomain();
	}

	async createComments(params: ActionPlanCreateCommentsRequestDTO): Promise<ActionPlanCreateCommentsDomain> {
		const url = this.basePath + '/comment';
		const { data } = await this.getInstance().post<ActionPlanCreateCommentsDTO>(url, params);
		return new ActionPlanCreateCommentsMapper(data).toDomain();
	}

	async updateCardRank(params: UpdateCardRankRequestDTO): Promise<UpdateCardRankResponseDTO> {
		const url = this.basePath + '/update-rank';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async findAllForBoard(params: ActionPlanCardListRequestDTO): Promise<ActionPlanCardListResponseDTO> {
		const url = this.basePath + '/board';
		const { data } = await this.getInstance().get<ActionPlanCardListResponseDTO>(url, { params });
		return data;
	}

	async downloadPdfEdit(params: ActionPlanPdfEditRequestDTO): Promise<string> {
		const url = this.basePath + `/${params.id}/pdf`;
		const { data } = await this.getInstance().post<string>(url, params);
		return data;
	}

	async createFromReport(
		params: CreateActionPlanFromReportRequestDTO
	): Promise<CreateActionPlanFromReportResponseDTO> {
		const url = this.basePath + '/report';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async getFileActionPlans(params: GetFileActionPlansRequestDTO): Promise<GetFileActionPlansResponseDTO[]> {
		const url = this.basePath + `/report/${params.file_id}`;
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}
}
