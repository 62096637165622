import { QUERY_KEYS } from '@/utils/query-keys';
import { ActionPlanService } from '@/infra/services/action-plan';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type Tasks = {
	title: string;
	due_date: Date;
	is_completed: boolean;
	responsible_user_id: string;
};

interface CreateActionPlanParams {
	company_id: string;
	organization_id: string;
	file_id: string;
	title: string;
	due_date: Date;
	responsible_user_id: string;
	investment_range: number;
	priority: number;
	tasks: Tasks[];
}

const Service = new ActionPlanService();

export const useCreateActionPlanFromReport = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (params: CreateActionPlanParams) => Service.createFromReport(params),
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_FROM_REPORT]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLANS_BY_REPORT]);
		}
	});
};
