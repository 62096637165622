import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { UserService } from '@/infra/services/user';

interface ResponsibleOption {
	readonly id: string;
	readonly name: string;
	readonly created_at: Date;
	readonly updated_at: Date;
}
interface GetResponsableOptionsParams {
	organization_id?: string;
	company_id?: string;
}

const transformResponsibleOptions = (data: ResponsibleOption[]) => {
	return data.map((responsible) => ({ value: responsible.id, label: responsible.name }));
};

const Service = UserService.getInstance();

export const useGetResponsableOptions = (params: GetResponsableOptionsParams) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_RESPONSIBLES_OPTIONS, params],
		queryFn: async () => Service.listOptions(params),
		enabled: !!params.organization_id && !!params.company_id,
		select: transformResponsibleOptions
	});
};
